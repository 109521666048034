<template>
  <div>
    <div
      class="row mt-5 w-85 big-div"
      style="position: relative; z-index: 100"
      :style="
        getPageDesign() +
        `zoom:${this.pageZoomValue ? this.pageZoomValue : 90}%;`
      "
      :key="`getStartedPage+${CurrentSelectedPage.id}`"
    >
      <div
        class="bg"
        v-if="getImageAlign == 'no' && getImageSrc(pageDesign.imageBlock, isDesktopView) !== ''"
        :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
        :style="
          getbackgroundImage(
            IsImageAdded,
            pageDesign.imageBlock,
            getImageAlign,
            isDesktopView
          ) + getBgDivStyles()
        "
      ></div>
      <div
        class="col-6 col-xs-12 col-md-6 col-sm-12 px-0 col-12 col-xs-12 px-0"
        v-show="showLeftImage"
        :class="{
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division': isDesktopView,
        }"
        :style="
          calculateImageWidth(
            pageDesign.imageBlock,
            isDesktopView,
            IsImageVariableWidthActive
          )
        "
      >
        <div
          @click="activeImageCustomize"
          :class="isDesktopView ? 'image-division' : null"
          :style="getImageOpacity(pageDesign.imageBlock.style)"
        >
          <div :style="height100(isDesktopView)">
            <LazyImage
              :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
              :style="
                getUniversalImageStyle(
                  isDesktopView,
                  pageDesign.imageBlock.style,
                  IsImageVariableWidthActive,
                  pageDesign.imageBlock
                ) + getImageRotation(pageDesign.imageBlock.style)
              "
              :class="[
                `pic-${getSelectedPage.order}`,
                getImageSrc(pageDesign.imageBlock, isDesktopView) ==
                '/images/default_start_page.png'
                  ? 'default-bg'
                  : '',
              ]"
              :src="
                MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))
              "
              alt
            />
          </div>
        </div>
        <button id="clear" class="hide close-icon" @click="deleteImage">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
              fill="black"
              fill-opacity="0.5"
            />
            <path
              d="M10.2857 23.2592C10.2857 24.4 11.1428 25.3333 12.1905 25.3333H19.8095C20.8571 25.3333 21.7143 24.4 21.7143 23.2592V10.8148H10.2857V23.2592ZM12.1905 12.8888H19.8095V23.2592H12.1905V12.8888ZM19.3333 7.70366L18.3809 6.66663H13.619L12.6666 7.70366H9.33331V9.77774H22.6666V7.70366H19.3333Z"
              fill="white"
            />
          </svg>
        </button>
      </div>

      <div
        :class="!IsImageAdded ? 'col-12' : imageAddedLayout"
        class=" "
        :style="
          getMainPaddingCss(pageDesign.pageStyles, isDesktopView) +
          getScrollCSS(
            pageDesign.pageStyles,
            isDesktopView,
            pageDesign.imageBlock.style
          ) +
          calculateContentWidth(
            pageDesign.imageBlock,
            isDesktopView,
            IsImageVariableWidthActive,
            pageDesign.pageStyles
          )
        "
      >
        <div>
          <draggable
            v-model="pageDesignBlocks"
            class="dragArea list-group"
            group="actions"
            v-bind="dragOptions"
            chosen-class="chosen"
            drag-class="drag"
            @start="dragging = true"
            @end="dragging = false"
            style="cursor: move"
             @change="handleDraggableElementDrop"
          >
            <div
              v-for="(pro, ind) in pageDesignBlocks"
              :key="'preview' + ind"
              draggable="false"
              :class="dragging ? 'dragElementBorder' : null"
              @click="changeCustomize(ind)"
           
            >
              <div
               
                :style="getHeightWidth(pro.style) + getDivBorderCss(pro)"
                class="content designBlockElement"
                style="position: relative"
                :id="`${pro.type}-${ind}`"
              >
                <EditorAction v-if="pro.customizeStatus" :deleteCondition="getDeleteButtonCondition(pro)" :currElementIndex="ind" :currElementType="pro.type"/>
                <div
                  v-if="pro.type == 'text'"
                  :style="
                    getColorCss(pro.style) +
                    getPaddingCss(pro.style) +
                    getMarginCss(pro.style, isDesktopView) +
                    getColorWithOpacity(pro.style) +
                    getTextRotate(pro.style) +
                    getLineHeight(pro.style) +
                    getTextAlign(pro.style) +
                    getFontStyle(pro.style, isDesktopView)
                  "
                  v-html="getEditorPersonalisedValueStyle(pro.content)"
                ></div>
                <!-- </VueDragResize> -->
                <template v-if="pro.type == 'timer'">
                  <Timer
                    :pro="pro"
                    :isDesktopView="isDesktopView"
                    isEditor="true"
                  />
                </template>
                <div
                  v-if="pro.type == 'button'"
                  :style="
                    'display:flex;justify-content:' + pro.style.position + ';'
                  "
                >
                  <button :style="getButtonCss(pro.style, isDesktopView)">
                    <span
                      :style="{
                        opacity: `${pro.style.textOpacity}%`,
                        fontSize: `${pro.style.fontSize}px`,
                      }"
                    >
                      {{ pro.text }}
                    </span>
                    {{ pro.style.alignItems }}
                  </button>
                </div>
                <div
                  v-if="pro.type == 'tooltip'"
                  :style="
                    'display:flex;justify-content:' + pro.style.position + ';'
                  "
                >
                  <button
                    :style="
                      getButtonCss(pro.style, isDesktopView) +
                      getCircularBtn(pro.isBtnRounded)
                    "
                    @mouseenter="pro.isTooltipVisible = true"
                    @mouseleave="pro.isTooltipVisible = false"
                  >
                    <span v-if="pro.useIcon">
                      <BIcon :icon="pro.selectedIcon" />
                    </span>

                    <span
                      v-else
                      :style="{
                        opacity: `${pro.style.textOpacity}%`,
                        fontSize: `${pro.style.fontSize}px`,
                      }"
                    >
                      {{ pro.text }}
                    </span>
                  </button>
                  <ToolTipComp
                    v-if="pro.isTooltipVisible"
                    :tooltipData="pro"
                    :isDesktopView="isDesktopView"
                  />
                </div>
                <div
                  v-if="pro.type == 'html'"
                  :style="
                    getMarginCss(pro.style, isDesktopView) +
                    getColorWithOpacity(pro.style) +
                    getTextRotate(pro.style) +
                    getTextAlign(pro.style)
                  "
                >
                  <HtmlElement :pro="pro" :isDesktopView="isDesktopView" />
                </div>
                <div
                  v-if="pro.type == 'imageComp'"
                  
                >
                  <InlineImageComp :pro="pro" :isDesktopView="isDesktopView" />
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </div>

      <div
        class="col-6 col-xs-12 col-md-6 col-sm-12 px-0 col-12 col-xs-12 px-0"
        v-show="showRightImage"
        :class="{
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division': isDesktopView,
        }"
        :style="
          calculateImageWidth(
            pageDesign.imageBlock,
            isDesktopView,
            IsImageVariableWidthActive
          )
        "
      >
        <div
          @click="activeImageCustomize"
          :class="isDesktopView ? 'image-division' : ''"
        >
          <div :style="height100(isDesktopView)">
            <LazyImage
              :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
              :style="
                getUniversalImageStyle(
                  isDesktopView,
                  pageDesign.imageBlock.style,
                  IsImageVariableWidthActive,
                  pageDesign.imageBlock
                ) +
                getImageOpacity(pageDesign.imageBlock.style) +
                getImageRotation(pageDesign.imageBlock.style)
              "
              :class="[
                `pic-${getSelectedPage.order}`,
                getImageSrc(pageDesign.imageBlock, isDesktopView) ==
                '/images/default_start_page.png'
                  ? 'default-bg'
                  : '',
              ]"
              :src="
                MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))
              "
              alt
            />
          </div>
        </div>
        <button id="clear" class="hide close-icon" @click="deleteImage">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
              fill="black"
              fill-opacity="0.5"
            />
            <path
              d="M10.2857 23.2592C10.2857 24.4 11.1428 25.3333 12.1905 25.3333H19.8095C20.8571 25.3333 21.7143 24.4 21.7143 23.2592V10.8148H10.2857V23.2592ZM12.1905 12.8888H19.8095V23.2592H12.1905V12.8888ZM19.3333 7.70366L18.3809 6.66663H13.619L12.6666 7.70366H9.33331V9.77774H22.6666V7.70366H19.3333Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import centralEventBus from "./centralEventBus";
import draggable from "vuedraggable";
import { mapGetters ,mapMutations} from "vuex";
import cssFunctionMixin from "../../mixin/cssFunction";
import ToolTipComp from "../../../quizPreviewComp/ToolTipComp.vue";
import HtmlElement from "../../../quizPreviewComp/HtmlElement.vue";
import Timer from "../../../quizPreviewComp/components/Timer.vue";

import EditorAction from "../../../../components/EditorActions.vue"

export default {
  mixins: [cssFunctionMixin],
  props: ["isDesktopView"],
  components: {
    draggable,
    ToolTipComp,
    HtmlElement,
    Timer,
    EditorAction,
    InlineImageComp: () =>
      import(
        /* webpackChunkName: "InlineImageComp" */ "../../../quizPreviewComp/components/InlineImageComp.vue"
      ),
  },
  data() {
    return {
      selectedPage: "questionPageOne",
      editMode: false,
      dragging: false,
      activeSideBar: "pageStyle",
      File: [],
      ChooseImageModal: false,
      //   pageDesign: {},
      activeIndex: 0,
      imageUrl: null,
      upHere: false,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
        chosenClass: "custom-chosen",
      };
    },
    pageDesign() {
      return this.getSelectedPage.pageDesign;
    },
    pageDesignBlocks: {
      get() {
        return this.pageDesign.blocksArray;
      },
      set(newValue) {
        centralEventBus.$emit("handleBlocksArrayDraggable", newValue);
      },
    },
    getImageAlign(){
      if (this.pageDesign.pageStyles.useMobileImageAlign && !this.isDesktopView) {
     return  this.pageDesign.pageStyles.mobileImageAlign ;
    } else {
      return this.pageDesign.pageStyles.imageAlign;
    }
    },

    showLeftImage() {
      return   (
        (this.isDesktopView && this.pageDesign.imageBlock.src !== '' &&
          this.pageDesign.imageBlock.style.displayDesktop &&
          this.getImageAlign == "left") ||
        (!this.isDesktopView &&
        this.pageDesign.imageBlock.src !== '' &&
          this.pageDesign.imageBlock.style.displayMobile &&
          (this.getImageAlign == "left" ||
            this.getImageAlign == "right"))
      );
    },
    showRightImage() {
      return (
        this.isDesktopView &&
        this.pageDesign.imageBlock.style.displayDesktop &&
        this.getImageAlign == "right"
      );
    },
    ...mapGetters([
      "getSelectedPage",
      "getUploadedImage",
      "getTotalPages",
      "getEditorPageView",
      "getPlanData",
      "getPageZoom",
      "getCustomNavFlexGrowValue"
    ]),
    CustomNavFlexGrowValue() {
      return this.getCustomNavFlexGrowValue;
    },
    pageZoomValue() {
      return this.getPageZoom;
    },
    UserPlanData() {
      return this.getPlanData;
    },
    CurrentSelectedPage() {
      return this.getSelectedPage;
    },

    IsImageAdded() {
      if (
        this.getImageSrc(this.pageDesign.imageBlock, this.isDesktopView) !==
          "" 
      )
        return true;
      return false;
    },
    IsImageVariableWidthActive() {
      return this.IsImageAdded && this.getImageAlign != "no"
        ? true
        : false;
    },
    imageAddedLayout() {
      if (this.getImageAlign == "no") return "col-12";
      if (!this.isDesktopView) return "col-12";
      return "col-12 col-xs-12 col-md-6 col-sm-12  col-12 col-xs-12";
    },
  },

  methods: {

    async handleDraggableElementDrop(e) {
      if(e.added){
        const {newIndex , element} = e.added
        let obj = {
          index:newIndex,
          item:element.element_type,
          currPage:this.getSelectedPage.value
        }
        centralEventBus.$emit("handleAddElementDrop", obj);
      }
      
    },

    ...mapMutations(['setCurrSidebarView']),
    getDivBorderCss(block) {
      if (block.customizeStatus) return "border:2px solid #4065d5 !important;";
      // else if (block.hoverStatus) return "border:2px solid #ada9a9";
      else return "border:2px solid transparent";
    },

  async  activeImageCustomize() {
      await this.setCurrSidebarView('pageDesign')
      centralEventBus.$emit("activeImageCustomize");
    },

    getPageDesign() {
      let design =
        "text-align:" +
        this.pageDesign.pageStyles.textAlign +
        ";color:" +
        this.pageDesign.pageStyles.color +
        "; background:" +
        this.pageDesign.pageStyles.backgroundColor +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; color:" +
        this.pageDesign.pageStyles.color +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; height:" +
        this.pageDesign.pageStyles.height +
        "px;" +
        (this.getEditorPageView == "desktop"
          ? "width:" + this.pageDesign.pageStyles.width + "px;"
          : "width:350px;");

      return design;
    },

    deleteImage() {
      centralEventBus.$emit("deleteImage");
    },
    deleteBlock(index) {
      centralEventBus.$emit("deleteBlock", index);
    },

    getDeleteButtonCondition(pro) {
      if (pro.customizeStatus) {
        if (pro.type == "text" && this.minTextCount <= 1) return false;
        return true;
      }
    },
  async  changeCustomize(index) {
     await this.setCurrSidebarView('pageDesign')
      centralEventBus.$emit("changeCustomize", index);
    },
  },
};
</script>

<style>
.image-division {
  height: 100%;
  cursor: pointer;
}
.designBlockElement:hover{
  border:2px solid #ada9a9 !important;
}



</style>
